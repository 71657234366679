<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon
          large
        >
          mdi-switch
        </v-icon>
        OLT
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="olts"
        :search.sync="search"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions : [10,50,100]}"
        :loading="loading"
        loading-text="Loading..."
        :server-items-length="totalOlts"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-icon
            large
            :color="setStatusColor(item.enable, item.uptime.uptime)"
          >
            mdi-switch
          </v-icon>
        </template>
        <template v-slot:item.uptime.uptime="{ item }">
          <span>
            {{ calSysUpTime(item.uptime.uptime) }}
          </span>
        </template>
        <template v-slot:item.uptime.updatedAt="{ item }">
          <span>
            <!-- {{ formatDate(item.uptime.updatedAt) }} -->
            <v-chip
              v-if="item.uptime.updatedAt"
              :color="getFromNowColor(item.uptime.updatedAt)"
              dark
            >
              {{ fromNow(item.uptime.updatedAt) }}
            </v-chip>
          </span>
        </template>
        <template v-slot:item.action="{ item }">
          <div class="text-center">
            <div>
              <v-btn
                small
                color="primary"
                class="ma-2"
                outlined
                :to="'olt/' + item._id"
              >
                <v-icon
                  left
                >
                  mdi-magnify
                </v-icon>
                Detail
              </v-btn>
              <v-btn
                small
                color="primary"
                class="ma-2"
                outlined
                :href="'telnet://' + item.ip "
              >
                <v-icon
                  left
                >
                  mdi-powershell
                </v-icon>
                Telnet
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

// import axios from 'axios'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

moment.locale('th')

export default {
  name: 'OltList',
  props: {

  },
  data() {
    return {
      search: '',
      // totalOlts: 0,
      options: {},
      // olts: [],
      // loading: false,
      headers: [
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Project',
          value: 'oltProject.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'IP Address',
          value: 'ip',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Brand',
          value: 'oltType.oltBrand.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Type',
          value: 'oltType.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Sector',
          value: 'officeSector.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Uptime',
          value: 'uptime.uptime',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Updated at',
          value: 'uptime.updatedAt',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      olts: 'olts',
      totalOlts: 'totalOlts',
      loading: 'isLoading',
    }),
    // ...mapState({
    //   olts: 'olts',
    //   totalOlts: 'totalOlts',
    // }),
  },
  watch: {
    options: {
      handler() {
        // this.getDataFromAPI()
        this.showOlts()
      },
      deep: true,
    },
    search: {
      handler() {
        // this.getDataFromAPI()
        this.showOlts()
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    ...mapActions({
      getOlts: 'getOlts',
    }),
    showOlts() {
      // this.loading = true
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
      }
      this.getOlts(queryParams)
    },
    setStatusColor(enable, uptime) {
      if ((enable) && (uptime !== null)) return 'success'
      if ((enable) && (uptime === null)) return 'error'
      return ''
    },
    calSysUpTime(upTime) {
      const uptimeSecond = upTime / 100
      const day = Math.floor(uptimeSecond / (86400))
      const hour = Math.floor((uptimeSecond % 86400) / 3600)
      const minute = Math.floor((uptimeSecond % 3600) / 60)
      const upTimeText = ` ${day} วัน ${hour} ชั่วโมง ${minute} นาที `
      return upTimeText
    },
    formatDate(date) {
      return moment(date).format('LLL')
    },
    fromNow(date) {
      if (date) {
        return moment(date).fromNow()
      }
      return null
    },
    getFromNowColor(date) {
      if (date) {
        const now = moment(new Date())
        const duration = moment.duration(now.diff(date))
        const minuteDiff = duration.asMinutes()
        if (minuteDiff < 1) return 'success'
        if (minuteDiff < 2) return 'yellow'
        if (minuteDiff < 3) return 'amber'
        if (minuteDiff < 4) return 'orange'
        if (minuteDiff < 5) return 'deep-orange'
        return 'red'
      }
      return null
    },
    // getDataFromAPI() {
    //   this.loading = true
    //   const queryParams = {
    //     params: {
    //       search: this.search,
    //       page: this.options.page,
    //       limit: this.options.itemsPerPage,
    //     },
    //   }
    //   axios.get('/olt', queryParams)
    //     .then((response) => {
    //       // JSON responses are automatically parsed.
    //       this.olts = response.data.docs
    //       this.totalOlts = response.data.total
    //       this.loading = false
    //     })
    //     .catch((e) => {
    //       this.errors.push(e)
    //       this.loading = false
    //     })
    // },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
